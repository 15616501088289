.pom-LegalDisclaimer {
  color: #fff;

  a {
    color: #a1bce6;
    margin: 10px;
  }
}

@media screen and (max-width: 767px) {
  .pom-LegalDisclaimer {
    color: #6a6d75;
    text-align: center;

    a {
      color: #245091;
    }
  }
}
