.pom-CustomizationOptionsButton {
  padding: 0 12px;
  height: 40px;
  max-width: 100%;
  display: inline-flex;
  font-family: $cera;
  font-size: $font-size-2;
  font-weight: $med;
  line-height: 40px;
  border: 0;
  outline: none;
  border-radius: 6px;
  background-color: color(blue, 300);

  span {
    display: inline-block;
    color: $white;
    max-width: calc(100% - 24px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .replaceArrow {
    height: 100%;
    width: 24px;

    span {
      max-width: none;
    }
  }

  .replaceArrow svg {
    margin-top: 12px;
    padding: 0 5px;
  }

  svg {
    align-self: center;
    padding-left: 12px;
  }

  &--withArrows {
    display: block;
    white-space: nowrap;
  }

  &--hoverable {
    &:hover,
    &:focus {
      cursor: pointer;
      background-color: color(blue, 200);
    }
  }
}
