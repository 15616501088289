/* stylelint-disable selector-class-pattern */
/* stylelint-disable declaration-block-no-duplicate-properties */
/* stylelint-disable max-line-length */
.pom-fade-out-top {
  animation: pom-fade-out-top 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: pom-fade-out-top 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation pom-fade-out-top
 * ----------------------------------------
 */
@keyframes pom-fade-out-top {
  0% {
    transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes pom-fade-out-top {
  0% {
    transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}

.pom-fade-in-top {
  animation: pom-fade-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: pom-fade-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation pom-fade-in-top
 * ----------------------------------------
 */
@keyframes pom-fade-in-top {
  0% {
    transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pom-fade-in-top {
  0% {
    transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.pom-SuccessIcon {
  &__ellipse {
    width: 32px;
    height: 32px;
    background-color: rgb(255 255 255 / 20%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__check {
    opacity: 1;
    display: inline-block;
    height: 20px;
  }
}

.pom-ToastContainerWrapper {
  &.Toastify__toast-container {
    width: max-content;
    top: 10px;
  }

  @media only screen and (max-width: 480px) {
    &.Toastify__toast-container {
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.pom-Toast {
  --toastify-color-success: #1eb292;
  --toastify-font-family: "Cera Pro", "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;

  &.Toastify__toast {
    border-radius: 10px;
    padding: 10px 26px;
    box-shadow: 0 8px 13px 0 rgb(0 0 0 / 6%);
  }

  .Toastify__toast-body {
    .Toastify__toast-icon {
      width: 32px;
      margin-right: 16.9px;
    }
  }
}
/* stylelint-enable max-line-length */
/* stylelint-enable selector-class-pattern */
/* stylelint-enable declaration-block-no-duplicate-properties */
