/* stylelint-disable selector-class-pattern */
.pom-ProductSpecifications {
  padding-top: 16px;
}

.pom-ProductSpecifications--flex__center {
  display: flex;
  align-items: center;

  & > span {
    display: flex;
  }
}

.pom-ProductSpecifications--divider {
  width: 1px;
  height: 22px;
  background-color: $gray-300;
  margin: 0 12px 0 10px;

  &__vertical {
    padding-top: 16px;
  }
}

.pom-ProductSpecifications--campaignSection {
  & > span {
    margin-right: 8px;
  }
}

.pom-ProductSpecifications--priceSection {
  font-size: $xsmall;
  font-family: $cera;
  line-height: 1;

  .pom-ProductSpecifications--price {
    color: $gray-700;
    margin-right: 8px;

    &__negative {
      color: $red-300;
    }

    &__positive {
      color: $green-100;
    }
  }

  .pom-ProductSpecifications--quantifier {
    margin: 0;
    color: $gray-600;
  }
}

.pom-ProductSpecifications--winePairing {
  padding-top: 16px;
}
/* stylelint-enable selector-class-pattern */
