$overflowBadgeBorder: #d2d5db;

.pom-Badge {
  padding: 6px 10px;
  color: color(gray, 600);
  background: color(gray, 200);
  border-radius: 4px;
  font-size: $xxsmall;
  font-family: $cera;
  font-weight: $med;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  line-height: inherit;

  .pom-Badges--minimalist & {
    background: 0;
    border: 0;
    padding: 0;
    color: $gray-600;
    margin-bottom: 0;
    font-weight: $regular;

    // May add later when more than one badge is present for 3ds
    &::before {
      content: " \2022  ";
    }

    &--overflow {
      &::before {
        content: "";
      }
    }
  }

  &:hover,
  &:focus {
    color: $ba-blue-line;
  }

  &:last-child {
    margin-right: 0;
  }

  &--stacked {
    clear: both;
  }

  &--overflow {
    background: #fff;
    border: 1px solid $overflowBadgeBorder;

    svg {
      position: relative;
      top: -2px;
      margin-left: 3px;
    }
  }
}

.pom-CampaignBadge {
  padding: 7px 12px;
  color: $white;
  background: #00b5bd;
  border-radius: 13px;
  font-size: $milli;
  line-height: 1;
  letter-spacing: 0.92px;
  font-family: $cera;
  font-weight: $bold;
  text-transform: uppercase;
  display: inline-block;
}
