@import "styles/definitions";

.pom-TextArea {
  margin-top: 10px;

  textarea {
    @include input(medium);

    margin: 0;
    background-color: $white;
    font-size: 13px;
    font-family: $helvetica;
    line-height: 18px;
    padding: 8px;
    resize: none;
    height: 52px;
  }
}

.pom-TextArea--full {
  width: 100%;
}
