.pom-FeatureBadge {
  padding: 6px 8px;
  border-radius: 4px;
  display: inline-block;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.1em;
  box-sizing: border-box;
  text-align: center;
  font-size: $xxsmall;
  font-weight: $med;
  font-family: $cera;
  background-color: rgb(237 134 0 / 95%);
  color: $white;

  &--redesign {
    background-color: $ba-blue;
    color: #fff;
    font-size: 13px;
    border-radius: 0;
    letter-spacing: 0.11em;
    line-height: 20px;
    padding: 4px 7px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  &--chrissy-teigen {
    background-color: rgb(251 223 235 / 95%);
    color: #f0597e;
  }

  &--whole-30 {
    background-color: #29b5cf;
    color: #fff;
  }

  &--bobs-burgers {
    background-color: rgb(249 223 40 / 97%);
    color: #f9152c;
    padding: 2px 12px;
    border: 3px solid #f9152c;
    box-shadow: 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 2px;
  }

  &--sold-out {
    background-color: $gray-500;
    color: #fff;
  }

  &--premium {
    background-color: $green-100;
    color: $white;
  }
}
