.pom-Tooltip {
  position: absolute;
  box-sizing: border-box;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin: 0 auto;
  z-index: 2;
  font-family: $cera;
  font-size: 14px;
  line-height: 22px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 8%);
  width: 90%;
  left: 5%;
  text-align: center;

  &:hover {
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 15%);
  }

  &::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 15px 0;
    transform: translate(-50%, 0);
  }

  &--up::after {
    bottom: inherit;
    top: -10px;
    border-width: 0 15px 20px;
  }
}

.pom-Tooltip--blue {
  background-color: $blue-400;

  &::after {
    border-color: $blue-400 transparent transparent;
  }
}

.pom-Tooltip--blue.pom-Tooltip--up {
  &::after {
    border-color: transparent transparent $blue-400;
  }
}

.pom-Tooltip--green {
  background-color: $green-300;

  &::after {
    border-color: $green-300 transparent transparent;
  }
}

.pom-Tooltip--gray {
  background-color: $gray-700;

  &::after {
    border-color: $gray-700 transparent transparent;
  }
}

.pom-Tooltip--white {
  background-color: $white;
  color: #000;
  border: 1px solid $gray-300;

  &::after {
    border-color: $white transparent transparent;
  }
}

.pom-Tooltip-link {
  color: white;
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-transform: uppercase;
}

.pom-Tooltip--centered-arrow {
  &::after {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
