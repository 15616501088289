.pom-closeButton {
  position: absolute;
  display: block;
  width: 19px;
  height: 19px;
  top: 16px;
  right: 16px;
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: inherit;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.pom-closeButton--rounded {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $white;

  &:hover,
  &:focus {
    background-color: color(gray, 200);
  }

  svg {
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
