/* stylelint-disable */

@font-face {
  font-family: "Cera Pro";
  src: url("./cerapro/cerapro-regular-webfont.woff2") format("woff2"),
    url("./cerapro/cerapro-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./cerapro/cerapro-regularitalic-webfont.woff2") format("woff2"),
    url("./cerapro/cerapro-regularitalic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./cerapro/cerapro-medium-webfont.woff2") format("woff2"),
    url("./cerapro/cerapro-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./cerapro/cerapro-mediumitalic-webfont.woff2") format("woff2"),
    url("./cerapro/cerapro-mediumitalic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./cerapro/cerapro-bold-webfont.woff2") format("woff2"),
    url("./cerapro/cerapro-bold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./cerapro/cerapro-bolditalic-webfont.woff2") format("woff2"),
    url("./cerapro/cerapro-bolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

//CHRONICLE DECK

@font-face {
  font-family: "Chronicle Deck";
  src: url("./chronicledeck/chronicledeck-roman-web.woff2") format("woff2"),
    url("./chronicledeck/chronicledeck-roman-web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Chronicle Deck";
  src: url("./chronicledeck/chronicledeck-italic-web.woff2") format("woff2"),
    url("./chronicledeck/chronicledeck-italic-web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Chronicle Deck";
  src: url("./chronicledeck/chronicledeck-semibold-web.woff2") format("woff2"),
    url("./chronicledeck/chronicledeck-semibold-web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Chronicle Deck";
  src: url("./chronicledeck/chronicledeck-semibolditalic-web.woff2")
      format("woff2"),
    url("./chronicledeck/chronicledeck-semibolditalic-web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Chronicle Deck";
  src: url("./chronicledeck/chronicledeck-bold-web.woff2") format("woff2"),
    url("./chronicledeck/chronicledeck-bold-web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Chronicle Deck";
  src: url("./chronicledeck/chronicledeck-bolditalic-web.woff2") format("woff2"),
    url("./chronicledeck/chronicledeck-bolditalic-web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

//CHRONICLE SSM

@font-face {
  font-family: "Chronicle Ssm";
  src: url("./chroniclessm/0BA92CA046003CC5C.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
//

@font-face {
  font-family: "Chronicle Ssm";
  src: url("./chroniclessm/4DC90B39EBB17843B.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Chronicle Ssm";
  src: url("./chroniclessm/B44333C0D9D93C061.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Chronicle Ssm";
  src: url("./chroniclessm/A78EE21D496E13DB1.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Chronicle Ssm";
  src: url("./chroniclessm/9268B79276279483C.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Chronicle Ssm";
  src: url("./chroniclessm/6670EAD7F80242FE4.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}