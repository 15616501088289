.pom-Badges {
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 16px;

  &--minimalist {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0 0 0 5px;

    &__column {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      & div:nth-of-type(2n) {
        display: none;
        line-height: 14px;
      }
    }
  }
}
