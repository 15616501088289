.pom-FollowUs {
  margin: 0;
  padding: 20px;

  .pom-FollowUsHeader {
    color: $gray-700;
    font-style: normal;
    font-size: $xsmall;
    font-weight: $med;
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase;
    font-family: $cera;
  }

  ul {
    display: flex;
    padding: 0;
    justify-content: center;
  }

  li {
    list-style: none;
    margin: 16px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;

      span {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .pom-FollowUs {
    padding: 0;

    .pom-FollowUsHeader {
      display: none;
    }

    li {
      padding: 10px 0;
    }
  }
}
