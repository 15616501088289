.pom-pairing {
  display: flex;
  align-items: center;
}

.pom-pairing-image {
  height: 30px;
  width: 30px;
  margin: 0 8px 0 0;
}

.pom-pairing-title {
  width: 120px;
  height: 15px;
  font-family: $cera;
  font-size: $small;
  font-weight: $med;
  line-height: 1;
  color: #424242;
}
