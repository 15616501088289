/* stylelint-disable */

$regularItemWidth: 45px;
$rectangleItemWidth: 49px;
$defaultBorderWidth: 1px;

@mixin setPositions($left) {
  @for $j from 1 through 10 {
    @for $i from -1 through 9 {
      &[data-selected="#{$i}"][data-length="#{$j}"]::after {
        $var: percentage(calc($i * 1 / $j));
        left: calc(#{$var} - #{$left});
      }
    }
    &[data-length="#{$j}"] {
      &::after,
      li {
        width: percentage(calc(1 / $j));
      }
    }
  }
}

.pom-QuantitySelector {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  border: solid rgba(117, 129, 167, 0.25) $defaultBorderWidth;
  background: transparent;
  text-align: center;
  box-shadow: 0 2px 10px 0 rgba(195, 195, 195, 0.25);
  overflow: hidden;
  margin: 0 2px;
  padding: 0;
  list-style: none;
  white-space: nowrap;

  li {
    min-width: $regularItemWidth;
    display: inline-block;
  }

  li button {
    white-space: nowrap;
    width: 100%;
    font-family: $cera;
    font-size: 16px;
    position: relative;
    z-index: 1;
    padding: 10px;
    transition: color;
    transition-delay: 0.1s;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }

  li.selected button {
    color: white;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    color: white;
    background-color: $blue-400;
    transition:
      left 0.2s cubic-bezier(0.93, 0.76, 0.49, 1.13),
      background-color 0.2s;
  }

  @include setPositions(0px);

  &.pom-QuantitySelector__rectangle {
    border-radius: 12px;
    background: white;
    box-shadow: none;
    margin: 0;

    li {
      min-width: $rectangleItemWidth;
    }

    li button {
      color: $gray-600;
      font-size: 20px;
      font-weight: $font-weight-bold;
      padding: 13px;
    }

    li.selected button {
      color: white;
    }

    &::after {
      background-color: $blue-500;
      border-radius: 12px;
    }
  }

  &.pom-QuantitySelector__rounded {
    height: 45px;
    border-radius: 25px;
    background: $gray-300;
    border: none;
    box-shadow: none;
    overflow: visible;
    margin: 0;

    li {
      min-width: $rectangleItemWidth;
    }

    li button {
      color: $ba-blue;
      font-size: 12px;
      font-weight: $font-weight-bold;
      padding: 16px 30px;
      letter-spacing: 2px;
    }

    li.selected button {
      color: white;
    }

    &::after {
      background-color: $ba-blue;
      height: 50px;
      border-radius: 25px;
      top: 75%;
      transform: translateY(-75%);
    }
  }

  &.pom-QuantitySelector__circle {
    height: 45px;
    border-radius: 30px;
    border: none;
    background: $gray-300;
    box-shadow: none;
    margin: 0;
    overflow: visible;

    @include setPositions(3.5px);

    li {
      min-width: $regularItemWidth;
      padding: 2.5px 0;
    }

    li button {
      color: $ba-blue;
      font-size: 13px;
      font-weight: $font-weight-bold;
    }

    li.selected button {
      color: white;
    }

    &::after {
      background-color: $ba-blue;
      height: 50px;
      width: 50px !important;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
    }
  }

  &.pom-QuantitySelector__circle {
    height: 45px;
    border-radius: 30px;
    border: none;
    background: $gray-300;
    box-shadow: none;
    margin: 0;
    overflow: visible;

    @include setPositions(3.5px);

    li {
      min-width: $regularItemWidth;
      padding: 2.5px 0;
    }

    li button {
      color: $ba-blue;
      font-size: 13px;
      font-weight: $font-weight-bold;
    }

    li.selected button {
      color: white;
    }

    &::after {
      background-color: $ba-blue;
      height: 50px;
      width: 50px !important;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
    }
  }

  &.pom-QuantitySelector__block {
    height: 38px;
    border-radius: 2px;
    border: solid 1px $gray-400;
    background: $white;
    box-shadow: none;
    margin: 0;
    overflow: visible;

    li {
      min-width: $rectangleItemWidth;
    }

    li button {
      color: $ba-blue;
      font-size: 22px;
      font-weight: normal;
      padding: 0;
      height: 38px;
    }

    li.selected button {
      color: $ba-blue;
      font-weight: $med;
      color: $white;
      border-radius: 2px;
    }

    &::after {
      background-color: $ba-blue;
      border-radius: 2px;
    }
  }

  &.pom-QuantitySelector__block-rounded {
    height: 38px;
    border-radius: 6px;
    border: none;
    outline: solid 1px $gray-400;
    outline-offset: -1px;
    background: $white;
    box-shadow: none;
    margin: 0;
    overflow: visible;

    li {
      min-width: $rectangleItemWidth;
    }

    li button {
      color: $ba-blue;
      font-size: 22px;
      font-weight: normal;
      padding: 0;
      height: 38px;
    }

    li.selected button {
      color: $ba-blue;
      font-weight: $med;
      color: $white;
      border-radius: 6px;
    }

    &::after {
      background-color: $ba-blue;
      border-radius: 6px;
    }
  }

  &.pom-QuantitySelector__rounded-large-text {
    height: 38px;
    border-radius: 58px;
    background: $gray-200;
    border: none;
    box-shadow: none;
    overflow: visible;
    margin: 0;

    li {
      min-width: 46px;
      margin: auto 0;
    }

    li button {
      color: $ba-blue;
      font-size: 19px;
      font-weight: $regular;
      font-family: $cera;
      padding: 8px 0px;
      margin: auto 0;
      letter-spacing: 2px;
    }

    li.selected button {
      color: white;
    }

    &::after {
      background-color: $ba-blue;
      height: 38px;
      border-radius: 58px;
      top: 75%;
      transform: translateY(-75%);
    }
  }
}
