.pom-navTitle {
  font-weight: 400;
  margin: 0;
  color: #fff;
  font-size: 14px;
}

.pom-privacyAndTerms {
  text-align: right;
}

.pom-navLink-Column {
  margin: 3px;
}

@media screen and (max-width: 767px) {
  .pom-navLink {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 12px;

    .pom-navLinkItem {
      padding-right: 16px;
      padding-bottom: 10px;
    }
  }

  .pom-navTitle {
    color: #d3d5db;
    text-align: center;
  }

  .pom-navLink-Column {
    .pom-navLink {
      flex-direction: column;
      text-align: center;
    }
  }
}
