/* stylelint-disable selector-class-pattern */
.pom-ProductCard__Icon {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 0;
  z-index: 1;

  .pom-Plus {
    fill: $secondary;

    path:last-of-type {
      stroke: $primary;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  .pom-Checkmark {
    fill: $primary;

    path:last-of-type {
      stroke: $secondary;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  .pom-Empty {
    opacity: 0;
  }

  .pom-Spinner {
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 8px;
    color: $primary;
  }

  &--selected {
    .pom-Spinner {
      color: $secondary;
    }
  }
}
/* stylelint-enable selector-class-pattern */
