.pom-ApronLoader {
  position: relative;
  text-align: center;
  z-index: 4;

  &--holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--spinner {
    height: 130px;
    width: 130px;
    border-top: 2px solid #0f346c;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    border-bottom: 2px solid transparent;
    animation: rotate 1.5s infinite linear;
    border-radius: 100%;
    padding: 2px;
    position: absolute;
    margin-top: -2px;
    margin-left: -2px;
  }

  p {
    font-family: $cera;
    font-size: 24px;
    font-weight: 100;
    margin-top: 100px;
    line-height: 1.5;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
