/* stylelint-disable selector-class-pattern */
.pom-FlashMessage {
  margin: 0;
  padding: 0;
  font-family: $cera;
  font-size: $medium;
  color: $white;
  font-weight: $med;
  display: flex;
  height: 56px;

  &--multiline {
    min-height: 56px;
    padding: 8px;

    .pom-FlashMessage__textContainer {
      .text {
        overflow: visible;
        white-space: normal;
      }
    }
  }

  @include breakpoint(xs) {
    font-weight: $semibold;
    font-size: $milli;
    height: auto;
  }
}

.pom-FlashMessage__containerSlide {
  transition: all 500ms cubic-bezier(0, 1, 0.5, 1);
  transition-delay: 0.5s;
  will-change: height, padding;

  &.isClosing {
    height: 0;
  }
}

.pom-FlashMessage__textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-grow: 2;
  width: 100%;

  .text {
    max-width: 75%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  @include breakpoint(xs) {
    text-align: initial;
    padding-left: 5%;
    align-items: flex-start;

    .text {
      max-width: 100%;
      overflow: visible;
      white-space: normal;
      position: relative;
      line-height: 1.75;
      padding: 12px 0;
    }
  }
}

.pom-FlashMessage--success {
  background-color: $green-200;

  .pom-FlashMessage__button {
    background-color: $green-200;
  }
}

.pom-FlashMessage--warning {
  background-color: $orange-100;

  .pom-FlashMessage__button {
    background-color: $orange-100;
  }
}

.pom-FlashMessage--error {
  background-color: $red-300;

  .pom-FlashMessage__button {
    background-color: $red-300;
  }
}

.pom-FlashMessage--info {
  background-color: $blue-600;

  .pom-FlashMessage__button {
    background-color: $blue-600;
  }
}

.pom-FlashMessage__button {
  margin: 0 3%;
  border: none;
  cursor: pointer;
  text-align: right;
  position: absolute;
  right: 0;
  align-self: center;

  @include breakpoint(xs) {
    position: relative;
  }

  g {
    stroke-width: 1;
  }

  &.isClosing {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    g {
      stroke: $gray-300;
    }
  }
}
/* stylelint-enable selector-class-pattern */
