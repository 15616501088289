/* stylelint-disable at-rule-no-unknown */
@mixin _input {
  box-sizing: border-box;
  font-family: $cera;
  padding: 0 10px;
  border: solid color(gray, 400) 1px;
  border-radius: 2px;
  color: color(gray, 700);
  transition: border-color 0.15s ease-in-out;
  width: 100%;

  &::placeholder {
    color: color(gray, 400);
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    border-color: color(gray, base);
  }

  &:focus {
    border-color: color(blue, 400);
  }

  &:disabled {
    color: color(gray, base);
    border-color: color(gray, 300);
    background-color: color(gray, 200);
    cursor: not-allowed;
  }
}

@mixin _input-small {
  @include text-2;

  height: 36px;
}

@mixin _input-medium {
  @include text-1;

  height: 46px;
}

@mixin _input-large {
  @include subhead-2;

  height: 56px;
}

@mixin _input-regular {
  @include subhead-2;

  height: 46px;
}

@mixin input-error {
  color: color(red);

  input {
    border-color: color(red);
  }

  select {
    border-color: color(red);
  }

  &:focus {
    border-color: color(red, 400);
  }
}

@mixin input($size) {
  @include _input;

  @if $size == "small" {
    @include _input-small;
  } @else if $size == "medium" {
    @include _input-medium;
  } @else if $size == "large" {
    @include _input-large;
  } @else if $size == "regular" {
    @include _input-regular;
  } @else {
    @warn "invalid input size: #{$size}";
  }
}

@mixin _input-select {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 6px center;

  &::-ms-expand {
    display: none;
  }
}
