/* -~-~-~-~- Blue Apron Brand -~-~-~-~- */

// Blue
$blue-700: #0d2a54;
$blue-600: #0f346c;
$blue-500: #245091;
$blue-400: #2e62af;
$blue-300: #6b96d6;
$blue-200: #a1bce6;
$blue-100: #c7dcfc;
$blue: $blue-600;
$lightBlue: #7581a7;
$darkBlue: $blue-700;
$borderBlue: $blue-700;
$borderDarkBlue: $blue-700;
$toggleHoverBlue: $blue-600;

// Gray
$gray-700: #303236;
$gray-600: #6a6d75;
$gray-500: #b1b5bd;
$gray-400: #d3d5db;
$gray-300: #e4e6eb;
$gray-200: #f5f6f8;
$gray-100: #fafbfc;
$gray: $gray-600;
$darkGray: $gray-600;
$darkerGray: $gray-700;
$lightGray: $gray-400;
$lighterGray: $gray-300;
$lighterGrayNav: #f7f7f7;
$lightBlueGray: #f7f7f9;
$toggleDefaultGray: #6e7194;
$toggleHoverGray: #cbccd9;
$landingBorderGray: #bfbfbf;
$landingGrayText: #868484;

// Whites
$white: #ffffff;
$offWhite: #fafbfc;

// Green
$green-400: #00876a;
$green-300: #009978;
$green-200: #00a682;
$green-100: #1eb392;
$green: $green-300;

// Yellow
$yellow-400: #e69d00;
$yellow-300: #f7b100;
$yellow-200: #ffc42e;
$yellow-100: #ffd059;
$yellow: $yellow-300;

// Orange
$orange: #f26226;
$lightOrange: #ff6c2f; // badge hover
$semiDarkOrange: #e7581d; // button hover
$darkOrange: #e7581d; // button active/focus

// Red
$red: #d64148;
$errorRed: #d64148;

// Cancel
$cancel: #9e3a00;

// Link
$linkColor: $blue;

// Footer
$footerBorder: $gray-300;

// Alerts
$alertWarning: #c09853;
$alertWarningDark: #fbeed5;
$alertError: #d64148;
$alertErrorDark: #eed3d7;
$alertErrorText: #fff;
$alertSuccess: #00a979;
$alertSuccessDark: #d6e9c6;
$alertSuccessText: #d3ebce;
$alertInfo: #3a87ad;
$alertInfoDark: #bce8f1;
$alertInfoText: #fff;

// Gray
$placeholderGray: #d9d9d9;
$formLabelGray: #404040;
$paragraphGray: #737373;
$tocGray: #a6a6a6;
