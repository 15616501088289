@import "styles/definitions";

.pom-InputField {
  &--hidden {
    /* HTML5 Boilerplate accessible hidden styles */
    [type="radio"],
    [type="checkbox"] {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }

  &--checkbox__custom {
    input[type="checkbox"] {
      position: relative;
      border: 2px solid $ba-blue-line;
      border-radius: 0 !important;
      appearance: none;
      cursor: pointer;
      top: 4px;
      height: 19px;
      width: 19px;

      &::before {
        position: absolute;
        content: "";
        display: block;
        top: -1px;
        left: 4px;
        width: 5px;
        height: 11px;
        border-style: solid;
        border-width: 0 3px 3px 0;
        transform: rotate(33deg);
        opacity: 0;
      }

      &:checked {
        color: $white;
        border-color: $ba-blue;
        background: $ba-blue;
        content: "";

        &::before {
          opacity: 1;
        }
      }
    }
  }

  &--label {
    @include label;

    input[type="checkbox"] {
      display: inline;
      margin-right: 8px;
    }
  }

  &--inline {
    display: inline;

    p {
      margin-bottom: 0;
      display: inline;
    }
  }

  &--radio__custom {
    position: relative;

    .pom-InputField--label {
      display: inline-block;
      position: relative;
      padding-left: 36px;
      margin: 0;
      cursor: pointer;
      text-transform: none;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 2px;
        width: 20px;
        height: 20px;
        background: $white;
        border-radius: 100%;
        box-sizing: border-box;
        border: 2px solid $ba-blue-line;
      }

      &::after {
        position: absolute;
        content: "";
        top: 7px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: $ba-blue-line;
        transition: all 0.2s ease;
      }
    }

    .pom-InputField--input {
      position: absolute;
      left: -9999px;

      &:not(:checked) {
        & + .pom-InputField--label {
          &::after {
            opacity: 0;
            transform: scale(0);
          }
        }
      }

      &:checked {
        & + .pom-InputField--label {
          &::after {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }
}
