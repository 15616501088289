/* stylelint-disable selector-class-pattern */
.pom-Product__Title {
  padding: 0;
  margin-top: 11px;

  p:first-child {
    font-size: $xlarge;
    font-weight: $font-weight-bold;
    line-height: 24px;
    font-family: $chronicledeck;
    color: $ba-blue-line;
    margin: 0;
  }

  p:last-child {
    font-size: $medium;
    line-height: 20px;
    font-family: $cera;
    color: #424242;
    margin: 0;
    padding-top: 5px;
    font-weight: $light;
    letter-spacing: -0.01em;
  }
}
/* stylelint-enable selector-class-pattern */
