/* stylelint-disable selector-class-pattern */
.pom-Input {
  @include label;

  display: block;
  flex: 1;

  input {
    @include input(regular);

    display: block;
    min-width: 260px;

    &[type="password"] {
      letter-spacing: 0.1em;
    }
  }

  &--error {
    @include input-error;

    color: $red-300;
  }

  &--disabled {
    @include label-disabled;
  }

  &--border-transparent {
    input {
      border: none;
      margin: 0;
    }
  }
}

.pom-InputError {
  @include error-message;

  color: $red-300;
}

.pom-Input__optional-tag {
  margin-left: 5px;
  text-transform: lowercase;
  color: color(gray);
}

.pom-Input__unchangeable {
  font-family: $cera;
  font-size: $medium;
  color: $gray-700;
  margin: 0;
  margin-right: 4px;
}

.pom-Input-helpTip {
  margin-left: 5px;
}

.pom-InputConditions {
  line-height: 18px;
  display: flex;
  flex-direction: column;
  color: $gray-600;
  font-weight: $med;
  text-transform: none;
  letter-spacing: normal;
  margin-bottom: 8px;

  &--error {
    color: $red-300;
  }
}

.pom-InputCondition--valid {
  color: $green-100;

  span {
    font-weight: $med !important;
  }
}

.pom-InputCondition-message {
  margin-left: 8px;
  font-size: $xsmall;
}

.pom-ToggleShowPassword {
  font-family: $cera;
  font-weight: 500;
  background: none;
  border: none;
  color: $gray-600;
  font-size: $small;
  text-transform: uppercase;
  margin-right: 6px;
  cursor: pointer;
}

.pom-Input-fieldWrapper {
  display: flex;
  flex-direction: row;
  border: solid #d3d5db 1px;
  margin-top: 8px;
  border-radius: 2px;

  &:hover {
    border-color: $gray-500;
  }

  &--focus {
    border-color: $ba-blue-line;

    &:hover {
      border-color: $ba-blue-line;
    }
  }

  &--error {
    border-color: $red-300;
  }

  &--border-transparent {
    border: none;
    margin: 0;
  }
}
/* stylelint-enable selector-class-pattern */
