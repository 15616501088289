.pom-ImageBadge {
  position: relative;
  display: inline-block;

  .pom-Tooltip {
    z-index: 1000;
    padding: 10px;
    bottom: 35px;
    min-width: max-content;
    visibility: hidden;
    left: -8px;
  }

  img:hover + .pom-Tooltip {
    visibility: visible;
    display: block;
  }

  .pom-Tooltip::after {
    left: 20px;
    bottom: -7px;
  }
}
