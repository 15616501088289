@import "styles/definitions";

.pom-Select {
  @include label;

  &--medium select {
    @include input-select-medium;
  }

  &--small select {
    @include input-select-small;
  }

  &--large select {
    @include input-select-large;
  }

  &--error {
    @include input-error;
  }

  &--disabled {
    @include label-disabled;
  }

  select {
    margin-top: 8px;
  }
}

.pom-Select--error-message {
  @include error-message;
}

.pom-Select--loading {
  padding: 10px 5px 9px;

  & > div {
    height: 25px;
    position: relative;
  }
}

.pom-Select--empty {
  text-transform: none;
  letter-spacing: initial;
  font-size: $medium;
  margin: 5px 0;
}

.pom-Select--unchangeable {
  font-family: $cera;
  font-size: $medium;
  color: $gray-700;
  margin: 0 4px 0 0;
  float: left;
  display: flex;
  align-items: center;
}
