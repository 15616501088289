.pom-NewsletterSignUp {
  margin: 0;
  padding: 20px;
  font-family: $cera;
  text-align: center;
  max-width: 330px;

  .pom-NewsletterSignUpHeader {
    color: $gray-700;
    font-style: normal;
    font-size: $xsmall;
    font-weight: $med;
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase;
    margin: 14px;
  }

  p {
    font-weight: 400;
    color: $gray-600;
    font-size: $xsmall;
  }

  ul {
    display: flex;
    padding: 0;
    justify-content: center;
  }

  li {
    list-style: none;
    margin: 16px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;

      span {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 962px) {
  .pom-NewsletterSignUp {
    padding: 0;
    margin: 0 auto;

    .pom-NewsletterSignUpHeader {
      display: none;
    }

    li {
      padding: 10px 0;
    }
  }
}

.pom-NewsletterInput {
  display: flex;
  flex-direction: row;
}
