.pom-CookDuration {
  display: flex;
  font-family: $cera;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
}

/* stylelint-disable selector-class-pattern */
.pom-CookDuration__text {
  color: color(gray, 600);
  margin: 0 0 0 8px;
  padding: 0;
  font-size: $xsmall;
  font-weight: $med;
  letter-spacing: 2px;
  text-transform: uppercase;

  &--quick-meal {
    color: color(green, 300);
  }
}
/* stylelint-enable selector-class-pattern */
