/* stylelint-disable property-no-vendor-prefix */
@import "styles/definitions";

@include breakpoint(xs) {
  #header {
    background-color: white;
    border-bottom: solid $placeholderGray 1px;
  }

  #view-content,
  #checkout-form {
    height: 100%;
  }

  // blocks silly iPhone landscape font-resizing
  body,
  html {
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -o-text-size-adjust: none;
    -webkit-text-size-adjust: none;
  }
}

.discount {
  text-align: center;
  font-weight: $light;

  span {
    font-weight: $semibold;
  }
}

.button-bar {
  box-sizing: border-box;
  background-color: white;
  width: 100%;
  padding: 15px;
  border-top: 1px solid rgb(0 0 0 / 15%);

  .btn {
    box-sizing: border-box;
  }
}

@include breakpoint(xs) {
  #checkout-form {
    padding-bottom: 76px;
    background-color: $lightBlueGray;
  }
}
