.pom-AppleStoreLink {
  width: 300px;
  height: 200px;
  display: block;
  position: relative;
  overflow: hidden;
}

.pom-AppleStoreLink img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
