/* stylelint-disable selector-list-comma-newline-after */
@mixin label {
  @include text-2;

  font-family: $cera;
  text-transform: uppercase;
  letter-spacing: 1.45px;
  margin-bottom: 8px;
  color: color(gray, 700);

  input, select {
    margin-top: 8px;
  }
}

@mixin label-disabled {
  color: color(gray, base);
}

@mixin error-message {
  @include text-1;

  text-transform: none;
  letter-spacing: normal;
  font-weight: $font-weight-medium;
  margin-top: 8px;
  color: color(red, 400);
}

@mixin input-select-large {
  @include input(large);
  @include _input-select;
}

@mixin input-select-medium {
  @include input(medium);
  @include _input-select;
}

@mixin input-select-small {
  @include input(small);
  @include _input-select;
}
