// Font Family
$cera: "Cera Pro", "Arial", sans-serif;
$chronicle: "Chronicle Ssm", "Georgia", serif;

// Font Sizes
$font-size-1: 12px;
$font-size-2: 14px;
$font-size-3: 16px;
$font-size-4: 18px;
$font-size-5: 22px;
$font-size-6: 26px;
$font-size-7: 30px;
$font-size-8: 48px;
$font-size-9: 64px;

// Font Weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

// Line Heights
$lh-title: 1.25;
$lh-copy: 1.5;

@mixin title-extra-large {
  font-size: $font-size-9;
  font-weight: $font-weight-bold;
  line-height: $lh-title;
}

@mixin title-large {
  font-size: $font-size-8;
  font-weight: $font-weight-bold;
  line-height: $lh-title;
}

@mixin title-1 {
  font-size: $font-size-7;
  font-weight: $font-weight-bold;
  line-height: $lh-title;
}

@mixin title-2 {
  font-size: $font-size-6;
  font-weight: $font-weight-bold;
  line-height: $lh-title;
}

@mixin title-3 {
  font-size: $font-size-5;
  font-weight: $font-weight-bold;
  line-height: $lh-title;
}

@mixin subhead-1 {
  font-size: $font-size-4;
  font-weight: $font-weight-bold;
  line-height: $lh-copy;
}

@mixin subhead-2 {
  font-size: $font-size-3;
  font-weight: $font-weight-regular;
  line-height: $lh-copy;
}

@mixin text-1 {
  font-size: $font-size-2;
  font-weight: $font-weight-regular;
  line-height: $lh-copy;
}

@mixin text-2 {
  font-size: $font-size-1;
  font-weight: $font-weight-regular;
  line-height: $lh-copy;
}
