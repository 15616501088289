/* ================================================== *\
    Typography Variables
\* ================================================== */

// Font Sizes
$tera: 46px;
$giga: 36px;
$mega: 30px;
$kilo: 26px;
$xxlarge: 24px;
$xlarge: 20px;
$large: 18px;
$medium: 16px;
$small: 15px;
$xsmall: 14px;
$xxsmall: 13px;
$milli: 12px;

// Font Weight
$light: 300;
$regular: 400;
$med: 500;
$semibold: 600;
$bold: 700;

// Font Families
$helvetica: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman", "Helvetica Neue Roman",
  "TeXGyreHerosRegular", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
$awesome: "FontAwesome";
$cera: "Cera Pro","HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman",
  "Helvetica Neue Roman", "TeXGyreHerosRegular", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
$chronicle: "Chronicle Ssm", "Georgia", serif;
$terrific: "Totally Terrific", "Chronicle Ssm", "Georgia", serif;

@mixin btnFont {
  font-family: $cera;
  font-weight: $med;
}

// Base Font
$base-font-family: $helvetica;
$base-font-size: $xsmall;
$base-font-weight: $regular;
$base-line-height: $xxlarge;
$base-font-color: $darkGray;

// Headings
$header-font-weight: $semibold;

// // font-url function
// $font-url-path: "~fonts/" !default;
// @if project() == ba {
//   $font-url-path: "";
// }
// @function font-url($url) {
//   @return url("#{$font-url-path}#{$url}");
// }

/* ================================================== *\
    Font Awesome
\* ================================================== */

// @font-face{
//   font-family:"FontAwesome";
//   src: font-url("fontawesome-webfont.woff2") format("woff2"),
//        font-url("fontawesome-webfont.woff") format("woff");
//   font-weight:normal;
//   font-style:normal;
// }
