.pom-GooglePlayStoreLink {
  margin: 0;
  display: inline-block;
}

.pom-GooglePlayStoreImg {
  background-image: url("./google-play-badge.png");
  background-repeat: no-repeat;
  background-size: 122px 50px;
  width: 122px;
  height: 50px;
}
