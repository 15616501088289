.pom-FooterNav {
  margin: 0;
  font-family: $cera;
  padding: 5% 12%;
  background-color: #0f346c;
  border-top: 1px solid #e5e5e5;
  font-size: 14px;

  li {
    line-height: 2em;

    &.pom-AppStoreLink {
      margin-top: 5px;
      margin-right: -12px;
      line-height: 0;

      @media screen and (max-width: 767px) {
        margin-right: 0;
      }
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  a {
    text-decoration: none;
    color: #a1bce6;
  }
}

@media screen and (min-width: 768px) {
  .pom-FooterNav {
    .pom-FooterNavList {
      display: flex;
      flex-flow: row wrap;
    }

    .pom-FooterNavList > * {
      flex: 1;
    }

    .pom-FooterNavItem {
      flex-grow: 2;

      ul {
        column-count: 3;
        column-gap: 1.25em;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .pom-FooterNav {
    background-color: #fafbfc;
    padding: 8px;

    a {
      color: #b1b5bd;
    }
  }
}
