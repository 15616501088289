@import "./HeaderBadges";
@import "./Title";

a.pom-Product--productLink {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.pom-Product {
  background-color: white;
  border-radius: 6px;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 375px;
  position: relative;
  transition: 0.3s ease-in-out;
  transition-property: box-shadow, border;
  padding-bottom: 24px;
  overflow: hidden;

  &:hover {
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 15%);
    border: 1px solid $gray-400;
  }

  &::after {
    content: " ";
    border-radius: inherit;
    border: 2px solid $ba-blue;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.3s ease-in-out;
    user-select: none;
  }

  &.pom-Product--sold-out {
    opacity: 0.5;
  }

  &.pom-Product--selected::after {
    opacity: 1;
  }
}

.pom-Product--header {
  position: relative;

  svg {
    filter: drop-shadow(0 0 8px rgb(0 0 0 / 16%));
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    svg {
      filter: drop-shadow(0 0 8px rgb(0 0 0 / 48%));
    }

    .pom-Checkmark { fill: $ba-blue-line; }
    .pom-Plus { path:last-of-type { fill: $ba-blue-line; } }
  }
}

.pom-Product--image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 200px;

  &--sold-out {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 200px;
    opacity: 0.46;
  }
}

.pom-Product--details {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  height: 100%;
}

.pom-Product--details-cell {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pom-Product--customizations-button {
  background-color: transparent;
  border: none;
  margin-top: 16px;
  padding: 0;
}
