/* stylelint-disable block-no-empty */
/* stylelint-disable selector-list-comma-newline-after */

/* ================================================== *\
    Typography
\* ================================================== */

/* ----------------------------------------
    Load Fonts
---------------------------------------- */

// Cera

/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2016 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      TypeMates
 * License URL: https://www.fontspring.com/licenses/typemates/webfont
 *
 *
 */

// @font-face {
//   font-family: "Cera Pro";
//   src: font-url("cerapro-regular-webfont.woff2") format("woff2"),
//     font-url("cerapro-regular-webfont.woff") format("woff");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Cera Pro";
//   src: font-url("cerapro-regularitalic-webfont.woff2") format("woff2"),
//     font-url("cerapro-regularitalic-webfont.woff") format("woff");
//   font-weight: 400;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Cera Pro";
//   src: font-url("cerapro-medium-webfont.woff2") format("woff2"),
//     font-url("cerapro-medium-webfont.woff") format("woff");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Cera Pro";
//   src: font-url("cerapro-mediumitalic-webfont.woff2") format("woff2"),
//     font-url("cerapro-mediumitalic-webfont.woff") format("woff");
//   font-weight: 500;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Cera Pro";
//   src: font-url("cerapro-bold-webfont.woff2") format("woff2"),
//     font-url("cerapro-bold-webfont.woff") format("woff");
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Cera Pro";
//   src: font-url("cerapro-bolditalic-webfont.woff2") format("woff2"),
//     font-url("cerapro-bolditalic-webfont.woff") format("woff");
//   font-weight: 600;
//   font-style: italic;
// }

// // Chronicle Deck
// @font-face {
//   font-family: "Chronicle Deck";
//   src: font-url("chronicledeck-roman-web.woff2") format("woff2"),
//     font-url("chronicledeck-roman-web.woff") format("woff");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Chronicle Deck";
//   src: font-url("chronicledeck-italic-web.woff2") format("woff2"),
//     font-url("chronicledeck-italic-web.woff") format("woff");
//   font-weight: 400;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Chronicle Deck";
//   src: font-url("chronicledeck-semibold-web.woff2") format("woff2"),
//     font-url("chronicledeck-semibold-web.woff") format("woff");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Chronicle Deck";
//   src: font-url("chronicledeck-semibolditalic-web.woff2") format("woff2"),
//     font-url("chronicledeck-semibolditalic-web.woff") format("woff");
//   font-weight: 500;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Chronicle Deck";
//   src: font-url("chronicledeck-bold-web.woff2") format("woff2"),
//     font-url("chronicledeck-bold-web.woff") format("woff");
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Chronicle Deck";
//   src: font-url("chronicledeck-bolditalic-web.woff2") format("woff2"),
//     font-url("chronicledeck-bolditalic-web.woff") format("woff");
//   font-weight: 600;
//   font-style: italic;
// }

/*
  Copyright (C) 2011-2016 Hoefler & Co.
  This software is the property of Hoefler & Co. (H&Co).
  Your right to access and use this software is subject to the
  applicable License Agreement, or Terms of Service, that exists
  between you and H&Co. If no such agreement exists, you may not
  access or use this software for any purpose.
  This software may only be hosted at the locations specified in
  the applicable License Agreement or Terms of Service, and only
  for the purposes expressly set forth therein. You may not copy,
  modify, convert, create derivative works from or distribute this
  software in any way, or make it accessible to any third party,
  without first obtaining the written permission of H&Co.
  For more information, please visit us at http://typography.com.
  280350-103405-20161222
*/

// @font-face {
//   font-family: "Chronicle Ssm";
//   src: font-url("0BA92CA046003CC5C.woff") format("woff");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Chronicle Ssm";
//   src: font-url("4DC90B39EBB17843B.woff") format("woff");
//   font-weight: 400;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Chronicle Ssm";
//   src: font-url("B44333C0D9D93C061.woff") format("woff");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Chronicle Ssm";
//   src: font-url("A78EE21D496E13DB1.woff") format("woff");
//   font-weight: 500;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Chronicle Ssm";
//   src: font-url("9268B79276279483C.woff") format("woff");
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Chronicle Ssm";
//   src: font-url("6670EAD7F80242FE4.woff") format("woff");
//   font-weight: 600;
//   font-style: italic;
// }

// Totally Terrific

// @font-face {
//   font-family: "Totally Terrific";
//   src: font-url("totally_terrific_typeface_ba-webfont.woff2") format("woff2"),
//     font-url("totally_terrific_typeface_ba-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

/* ----------------------------------------
    Body
---------------------------------------- */

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
  color: $base-font-color;
}

/* ----------------------------------------
    Headings
---------------------------------------- */

h1, h2, h3, h4, h5, h6 {
  font-weight: $header-font-weight;
  line-height: 1.2;
  font-family: $cera;
  color: $gray-700;
}

h1,
.alpha {
  font-size: $giga;
}

h2,
.beta {
  font-size: $xxlarge;
}

h3,
.gamma {
  font-size: $kilo;
}

h4,
.delta {
  font-size: $xlarge;
}

.epsilon {
  font-size: $large;
  color: $darkGray;
}

h6,
.zeta {
  font-size: $medium;
  color: $darkGray;
}

/* ----------------------------------------
    Titles
---------------------------------------- */

.page-title {}
.page-subtitle {}

/* ----------------------------------------
    Text
---------------------------------------- */

p { margin-bottom: 15px; }

em { font-style: italic; }
strong { font-weight: $bold; }

.tera { font-size: $tera; }
.giga { font-size: $giga; }
.mega { font-size: $mega; }
.kilo { font-size: $kilo; }
.large { font-size: $large; }
.medium { font-size: $medium; }
.small { font-size: $xsmall; }
.milli { font-size: $milli; }

/* ----------------------------------------
    Links
---------------------------------------- */

a {
  text-decoration: none;
  color: $linkColor;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:active {
    color: $darkBlue;
  }
}
