.pom-Pricing--loader {
  width: 25px;
}

.pom-Pricing--free {
  color: $green-100;
  font-weight: $bold;
}

.pom-Pricing--green {
  color: $green-100;
}

.pom-Pricing--cyan {
  color: $cyan;
  font-weight: $med;
}
