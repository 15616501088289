.pom-BlogPreview {
  padding: 20px;

  img {
    height: 70px;
    width: 70px;
    border-radius: 4px;
  }

  a {
    border-radius: 4px;
    color: $gray-700;
    overflow: hidden;
    text-decoration: none;
  }

  .pom-BlogHeader {
    color: $gray-700;
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase;
    font-family: $cera;
  }

  .pom-BlogContent {
    display: flex;
    justify-content: center;
    padding: 10px;

    .pom-LatestBlogTitle {
      width: 155px;
      font-family: $cera;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      color: #fff;
      font-style: normal;
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .pom-BlogPreview {
    display: none;
  }
}
