/* stylelint-disable selector-class-pattern */
.pom-Button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 2px;
  border: 0;
  user-select: none;
  text-align: center;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-family: $cera;
  font-weight: $semibold;
  font-size: $medium;
  margin: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 1;

  svg {
    margin-right: 9px;
    vertical-align: bottom;
  }
}

.pom-Button--mouseover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pom-Button--disabled {
  cursor: initial;

  .pom-Button__Payment {
    opacity: 0.5;
  }
}

.pom-Button--social {
  font-weight: $med;
  text-transform: none;

  &:hover {
    opacity: 0.9;
  }
}

.pom-Button__Image {
  display: inline-block;
  position: absolute;
  top: 23%;
  left: 5px;

  img {
    width: 100%;
    height: 100%;
  }
}

.pom-Button__Payment {
  display: inline-block;
  text-align: center;
  padding-top: 5px;
}

.pom-Button--google {
  img {
    width: 55px;
    height: 22px;
  }
}

.pom-Button--apple {
  img {
    width: 54px;
    height: 26px;
  }
}

.pom-Button--apple,
.pom-Button--google {
  .pom-Spinner {
    margin-top: -10px;
  }
}

.pom-Button--small {
  min-width: 74px;
  font-size: 12px;
  height: 36px;
  padding: 0 28px;

  .pom-Button__Spinner--center { left: calc(50% - 8px); }
  .pom-Button__Spinner--right { right: 3px; }

  .pom-Button__Image {
    width: 15px;
    height: 15px;
  }
}

.pom-Button--medium {
  min-width: 177px;
  font-size: 14px;
  height: 46px;
  padding: 0 33px;

  .pom-Button__Spinner--center { left: calc(50% - 8px); }
  .pom-Button__Spinner--right { right: 8px; }

  .pom-Button__Image {
    width: 20px;
    height: 20px;
  }
}

.pom-Button--large {
  min-width: 221px;
  font-size: 16px;
  height: 56px;
  padding: 0 65px;

  .pom-Button__Spinner--center { left: calc(50% - 8px); }
  .pom-Button__Spinner--right { right: 8px; }

  .pom-Button__Image {
    width: 25px;
    height: 25px;
  }
}

.pom-Button--full {
  display: block;
  width: 100%;
  height: 50px;
  margin: 0;

  .pom-Button__Spinner--center { left: calc(50% - 8px); }
  .pom-Button__Spinner--right { right: 8px; }

  .pom-Button__Image {
    top: 20%;
    width: 25px;
    height: 25px;
  }
}

@mixin pom-Button-colors($white-color, $base-color, $light-color, $dark-color) {
  &.pom-Button--pill-filled {
    color: $white-color;
    background-color: $base-color;

    @media screen and (min-width: 469px) {
      &:hover {
        background-color: $light-color;
      }
    }

    &:disabled {
      background-color: $dark-color;
    }
  }

  &.pom-Button--round-filled {
    color: $white-color;
    border-radius: 8px;
    background-color: $base-color;

    &:active,
    &:hover {
      background-color: $light-color;
    }

    &:disabled {
      opacity: 1;
      color: $gray-500;
      background-color: $dark-color;
    }
  }

  &.pom-Button--filled {
    color: $white-color;
    background-color: $base-color;
    border: 1px solid $base-color;

    @media screen and (min-width: 469px) {
      &:hover {
        background-color: $light-color;
        border: 1px solid $light-color;
      }
    }

    &:active {
      background-color: $dark-color;
      border: 1px solid $dark-color;
    }

    &:disabled {
      border: 1px solid transparent;
      background-color: rgba($base-color, 0.4);
    }
  }

  &.pom-Button--outline {
    border: 1px solid $base-color;
  }

  &.pom-Button--pill-outline {
    border: 2px solid $base-color;
  }

  &.pom-Button--outline,
  &.pom-Button--pill-outline {
    color: $base-color;
    background-color: $white-color;

    @media screen and (min-width: 469px) {
      &:hover {
        color: $light-color;
        background-color: $gray-200;
      }
    }

    &:active {
      color: $dark-color;
      border: 1px solid $dark-color;
    }

    &:disabled {
      color: rgba($base-color, 0.4);
      border: 1px solid rgba($base-color, 0.4);
    }
  }
}

.pom-Button--pill-outline,
.pom-Button--pill-filled {
  border-radius: 30px;
}

.pom-Button--link {
  background-color: transparent;
  min-width: fit-content;
  width: fit-content;
  height: auto;
  border: none;
  padding: 0;
  color: $gray-700;

  &:hover {
    color: $gray-600;
  }
}

.servingArrows {
  margin-left: 9px;
  float: right;
  width: 8px;
}

.servingArrows svg {
  margin-right: 0;
}

.pom-Button--blue {
  @include pom-Button-colors($white, $ba-blue, $ba-blue-line, $blue-600);
}

.pom-Button--lightBlue {
  @include pom-Button-colors($white, $cyan, #5cbfe6, $gray-300);
}

.pom-Button--red {
  @include pom-Button-colors($white, $red-300, $red-200, $red-400);
}

.pom-Button--white {
  @include pom-Button-colors($ba-blue, $white, $gray-200, $gray-300);
}

.pom-Button--green {
  @include pom-Button-colors($white, $green-200, $green-100, $green-300);
}

.pom-Button--gray {
  @include pom-Button-colors($white, $gray-600, $gray-500, $gray-700);
}

.pom-Button--orange {
  @include pom-Button-colors($white, $orange-200, $orange-100, $orange-300);
}

.pom-Button--facebook {
  @include pom-Button-colors($white, $blue-facebook, $blue-facebook, $blue-600);
}

.pom-Button--apple {
  @include pom-Button-colors($white, $black, $black, $black);
}

.pom-Button--google {
  @include pom-Button-colors($gray-400, $white, $white, $white);

  &.pom-Button--filled {
    border-color: $gray-400;

    &:disabled,
    &:hover {
      border-color: $gray-400;
    }
  }
}

.pom-Button--blueAndGray {
  @include pom-Button-colors($white, $ba-blue, $ba-blue-line, $blue-600);

  &:hover {
    background-color: $gray-200;
  }

  &:active {
    border: 1px solid $ba-blue;
  }

  &.pom-Button--outline,
  &.pom-Button--pill-outline {
    border: 1px solid $gray-300;
  }
}

.pom-Button--card {
  height: 36px;
  font-size: $xsmall;
  font-weight: $med;
  margin: 0;
  width: 100%;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid;
  border-color: $gray-300 !important;
  position: relative;

  .pom-Button__Spinner--right { right: 8px; }

  .pom-Card__Body--landscape & {
    border-radius: 0;
    border-top-left-radius: 4px;
    margin-bottom: 0;
    background-color: $blue-600;
    color: $white;

    &:hover {
      background-color: $white;
      color: $blue-600;
    }
  }
}

.pom-Button--card-md {
  padding: 0 20px;
  margin: 0;
  height: 40px;
  border-radius: 20px;
  font-size: $xxsmall;
  line-height: 1.15;
  letter-spacing: 2px;
}

@media screen and (max-width: 468px) {
  .pom-Card__Footer .pom-Button--card {
    height: 46px;
    border-bottom-left-radius: 4px;
  }
}

.pom-Button--card--loading {
  position: absolute;
  right: 38px;
  top: 8px;
}

.pom-Button--Footer--Placeholder--portrait {
  height: 36px;
}

.pom-Button--input-end {
  border-radius: 0 4px 4px 0;
  margin: 0;
  padding: 10px 15px;
  min-width: 25px;
  height: 20px;
  font-size: 15px;
  background-color: $blue-500;
  background-image: none;
  color: $white;
  letter-spacing: 1.5px;
  box-sizing: content-box;

  &:active {
    background-color: $blue-600;
  }

  &:hover {
    background-color: $blue-600;
  }
}
/* stylelint-enable selector-class-pattern */
